import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { HttpClientModule, HttpClient,HTTP_INTERCEPTORS} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { TourNgBootstrapModule } from 'ngx-tour-ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SidebarModule } from 'ng-sidebar';
// import { ToastrModule } from 'ngx-toastr';
import 'hammerjs';

// import { AngularFireModule } from '@angular/fire';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AuthService } from './service/auth/auth.service';
import { AuthService } from './auth.service';
import { LoginComponent } from './login/login.component';
import { JwtHelperService,JwtModule,JwtModuleOptions } from '@auth0/angular-jwt';
import { AuthGuardService } from './auth-guard.service';
import { AuthGuard } from './auth.guard';
import {TokenService} from 'app/services/token.service';
import { TokenInterceptorService } from 'app/services/token-interceptor.service';
import { ChankyaAppComponent} from './app.component';
import { RoutingModule } from "./app-routing.module";
import { MainComponent }   from './main/main.component';
import { AuthComponent }   from './auth/auth.component';
import { MenuToggleModule } from './core/menu/menu-toggle.module';
import { MenuItems } from './core/menu/menu-items/menu-items';
import { PageTitleService } from './core/page-title/page-title.service';
import { SessionDemoModule } from './session/session.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { APP_BASE_HREF, Location } from '@angular/common';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { ToastrModule } from 'ng6-toastr-notifications';
import { Ng5BreadcrumbModule, BreadcrumbService } from 'ng5-breadcrumb';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CKEditorModule } from 'ng2-ckeditor';
// import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
/********** Custom option for ngx-translate ******/
export function createTranslateLoader(http: HttpClient) {
   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
declare module "@angular/core" {
	interface ModuleWithProviders<T = any> {
	  ngModule: Type<T>;
	  providers?: Provider[];
	}
  }

// export const firebase = {
// 	apiKey: "AIzaSyAYQ701NLzFMFFtx-A71OzNfORfJhR1RvI",
// 	authDomain: "chankya-e0e7a.firebaseapp.com",
// 	databaseURL: "https://chankya-e0e7a.firebaseio.com",
// 	projectId: "chankya-e0e7a",
// 	storageBucket: "chankya-e0e7a.appspot.com",
// 	messagingSenderId: "531424365001"
// }

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};

const JWT_Module_Options: JwtModuleOptions = {
    config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['http://192.168.1.143/', 'foo.com', 'bar.com']
    }
};
export function tokenGetter() {
	return localStorage.getItem('token');
  }


@NgModule({
	imports: [
		CKEditorModule,
		Ng2SearchPipeModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		CdkTableModule,
		SidebarModule.forRoot(),
		ToastrModule.forRoot(),
		Ng5BreadcrumbModule.forRoot(),
		// Ng4LoadingSpinnerModule.forRoot(),
		RoutingModule,
		RouterModule,
		SessionDemoModule,
		NgMultiSelectDropDownModule.forRoot(),
		TourNgBootstrapModule.forRoot(),
		NgbModalModule,
		AgmCoreModule.forRoot({apiKey: 'AIzaSyBtdO5k6CRntAMJCF-H5uZjTCoSGX95cdk'}),
		PerfectScrollbarModule,
		MenuToggleModule,
		HttpClientModule,
		TranslateModule.forRoot({
		loader: {
			provide: TranslateLoader,
			useFactory: createTranslateLoader,
			deps: [HttpClient]
		}
		}),
		JwtModule.forRoot({
			config: {
			  tokenGetter: tokenGetter,
			  whitelistedDomains: ['http://192.168.1.143/', 'foo.com', 'bar.com'],
			  blacklistedRoutes: ['example.com/examplebadroute/']
			}
		  }),
		// ToastrModule.forRoot({
		// 	timeOut: 2000,
		// 	preventDuplicates: true
		// }),
		// AngularFireModule.initializeApp(firebase),
		// AngularFireAuthModule
    ],
	
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
	declarations: [
		ChankyaAppComponent,
		MainComponent,
		AuthComponent,
		LoginComponent,
	],
	bootstrap: [ChankyaAppComponent],
	providers:[
		MenuItems,
		PageTitleService,
		AuthService,
		AuthGuard,
		JwtHelperService,
		AuthGuardService,
		{ provide: APP_BASE_HREF, useValue: window['_app_base'] || '/' },
		{
			provide: LocationStrategy, useClass: HashLocationStrategy },
		TokenService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptorService,
			multi: true,
		  },
		PageTitleService
	]
})
export class ChankyaAppModule { }
