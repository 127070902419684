import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import {LoginComponent} from './login/login.component';
import { MainComponent }   from './main/main.component';
import { AuthComponent }   from './auth/auth.component';
import { ProfileComponent } from './profile/profile.component';


export const AppRoutes: Routes = [
   {
  path: '',
  redirectTo: 'login',
  pathMatch: 'full',
   },
   {
      path: 'login', 
      component: LoginComponent 
    },
    {
      path: '',
      component: MainComponent, 
      canActivate: [AuthGuard],
      children: [{
        // canActivate: [ScormloginGuard],
         path: 'dashboard', loadChildren: ()=> 
         import('./dashboard/dashboard.module').then(m => m.DashboardModule)
     
      },
      // {
      //   path: 'master',
      //   loadChildren: './master/master.module#MasterModule'
      // },{
      //   path: 'user',
      //   loadChildren: './user/user.module#UserModule'
      // },{
      //   path: 'student',
      //   loadChildren: './student/student.module#StudentModule'
      // },{
      //   path: 'pages',
      //   loadChildren: './blank/blank.module#BlankModule'
      // },{
      //   path: 'dailyactivity',
      //   loadChildren: './dailyactivity/dailyactivity.module#DailyactivityModule'
      // },{
      //   path: 'institution',
      //   loadChildren: './institution/institution.module#InstitutionModule'
      // },{
      //   path: 'instituteadmin',
      //   loadChildren: './institute-admin/institute-admin.module#InstituteAdminModule'
      // },{
      //   path: 'location',
      //   loadChildren: './location/location.module#LocationModule'
      // },
      {
       
        path: 'grade', loadChildren: ()=> 
        import('././grade/grade.module').then(m => m.GradeModule)
      },
      {
     
        path: 'board', loadChildren: ()=> 
        import('././/board/board.module').then(m => m.BoardModule)
      },
      {
        // path:'profile',
        // component:ProfileComponent
        path: 'profile', loadChildren: ()=> 
        import('./profile/profile.module').then(m => m.profileModule)
      },
      // {
      //   path: 'qualifications',
      //   loadChildren: './qualification/qualification.module#QualificationModule',
      // },
      // {
      //   path: 'subjects',
      //   loadChildren: './subject-categories/subject-categories.module#SubjectCategoriesModule',
      // },
      {
        // path: 'flashcard',
        // loadChildren: './flashcard/flashcard.module#FlashcardModule',
        path: 'flashcard', loadChildren: ()=> 
        import('./flashcard/flashcard.module').then(m => m.FlashcardModule)
      },
      // {
      //   path: 'appreciationcard',
      //   loadChildren: './appreciationcard/appreciationcard.module#AppreciationCardModule'
      // },{
      //   path: 'acadmeiccalendar',
      //   loadChildren: './academiccalendar/academiccalendar.module#AcademicCalendarModule'
      // },{
      //   path: 'feepayment',
      //   loadChildren: './feepayment/feepayment.module#FeePaymentModule'
      // },{
      //   path: 'announcement',
      //   loadChildren: './announcement/announcement.module#AnnouncementModule'
      // },{
      //   path: 'chat',
      //   loadChildren: './chat/chat.module#ChatModule'
      // },{
      //   path:'profile',
      //   component:ProfileComponent
      // },{
      //   path:'images',
      //   loadChildren: './images/images.module#ImagesModule'
      // },{
      //   path:'timetable',
      //   loadChildren: './timetable/timetable.module#TimetableModule'
      // },
      // {
      //   path:'studymaterial',
      //   loadChildren: './studymaterial/studymaterial.module#StudyMaterialModule'
      // },
      // {
      //   path: 'holidaycalendar',
      //   loadChildren: './holidaycalendar/holidaycalendar.module#HolidaycalendarModule'
      // },
      // {
      //   path: 'markattendance',
      //   loadChildren: './markattendance/markattendance.module#MarkattendanceModule'
      // },
      {
    
        path: 'masterflashcardconfig', loadChildren: ()=> 
        import('./masterflashcardconfig/masterflashcardconfig.module').then(m => m.MasterflashcardconfigModule)
      },
       {
        // path: 'masterflashcardsubject',
        // loadChildren: './masterflashcardsubject/masterflashcardsubject.module#MasterflashcardsubjectModule',
           path: 'masterflashcardsubject', loadChildren: ()=> 
           import('./masterflashcardsubject/masterflashcardsubject.module').then(m => m.MasterflashcardsubjectModule)
      },
      {
        
        path: 'pdf', loadChildren: ()=> 
        import('./pdf/pdf.module').then(m => m.PdfModule)
      },
      {
       
        path: 'pdftype', loadChildren: ()=> 
        import('./pdftype/pdftype.module').then(m => m.PdftypeModule)
      },
      
    ],
    },
    {
      path: '',
      component: AuthComponent,
      children: [{
    
        path: 'session', loadChildren: ()=> 
        import('./session/session.module').then(m => m.SessionDemoModule)
      }]
    },
 
];
@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule { }       

