import { Injectable } from '@angular/core';

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}


const systemadmin = [
  {
    state: 'dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'icon-speedometer icons',
  },
  // // {
  // //   state: 'institution',
  // //   name: 'Institution Management',
  // //   type: 'link',
  // //   icon: 'icon-book-open icons',
  // // },
  // // {
  // //   state: 'instituteadmin',
  // //   name: 'Institute Admins',
  // //   type: 'link',
  // //   icon: 'icon-user icons',
  // // },
  // {
  //   state: 'location',
  //   name: 'Locations Master',
  //   type: 'sub',
  //   icon: 'icon-map icons',
  //   children: [
  //     { state: 'country', name: 'Country Master' },
  //     { state: 'state', name: 'State Master' },
  //     { state: 'city', name: 'City Master' },
  //   ]
  // },
  {
    state: 'grade',
    name: 'Grades Master',
    type: 'link',
    icon: 'fa fa-graduation-cap',
  },
  {
    state: 'board',
    name: 'Boards Master',
    type: 'link',
    icon: 'fa fa-clipboard',
  },
  {
    state: 'pdftype',
    name: 'Master PDF Type',
    type: 'link',
    icon: 'fa fa-file-pdf-o',
  },
  // {
  //   state: 'subjects',
  //   name: 'Subjects Category Master',
  //   type: 'link',
  //   icon: 'fa fa-book',
  // },
  {
    state: 'flashcard',
    name: 'Flash Card',
    type: 'link',
    icon: 'fa fa-pencil-square-o'
  },

  //  {
  //   state: 'masterflashcardconfig',
  //   name: 'Master flash card config',
  //   type: 'link',
  //   icon: 'fa fa-pencil-square-o'
  // },
  
  {
    state: 'masterflashcardsubject',
    name: 'Master flash card Subjects',
    type: 'link',
    icon: 'fa fa-pencil-square-o'
  },
  {
    state: 'pdf',
    name: 'PDF',
    type: 'link',
    icon: 'fa fa-file-pdf-o'
  },
 
];

const instituteadmin = [
  {
    state: 'dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'icon-speedometer icons',
  },
  {
    state: 'master',
    name: 'Master Configuration',
    type: 'sub',
    icon: 'icon-grid icons',
    children: [
      {state: 'schools', name: 'Manage Schools'}
    ]
  },

  {
    state: 'user',
    name: 'User Management',
    type: 'sub',
    icon: 'icon-user icons',
    children: [
      {state: 'manage-user', name: 'Manage School Admin'}
    ]
  },
  
];

const schooladmin = [
  {
    state: 'dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'icon-speedometer icons',
  },

  {
    state: 'master',
    name: 'Master Configuration',
    type: 'sub',
    icon: 'icon-grid icons',
    children: [
      // {state: 'locations', name: 'Locations'},
      {state: 'boards', name: 'Boards'},
      {state: 'shifts', name: 'Shifts'},
      {state: 'grades', name: 'Grades'},
      {state: 'divisions', name: 'Divisions'},
      {state: 'subjects', name: 'Subjects'},
      {state: 'logintype', name: 'Access Rights' }
    ]
  },
  {
    state: 'user',
    name: 'Staff Management',
    type: 'sub',
    icon: 'icon-user icons',
    children: [
      {state: 'manage-user', name: 'Manage Staff'},
      {state: 'manage-group', name: 'Manage Group'},
      {state: 'assign-user', name: 'Assign Staff'}
    ]
  },
  {
    state: 'student',
    name: 'Student-Parent Management',
    type: 'link',
    icon: 'fa fa-child',
  },
  {
    state: 'dailyactivity',
    name: 'Class Activity',
    type: 'link',
    icon: 'fa fa-tasks',
  },
  
  {
    state: 'appreciationcard',
    name: 'Appreciation card',
    type: 'link',
    icon: 'fa fa-id-card',
  },
  {
    state: 'acadmeiccalendar',
    name: 'Academic calendar',
    type: 'link',
    icon: 'fa fa-calendar',
  },
  // {
  //   state: 'feepayment',
  //   name: 'Fee Payment',
  //   type: 'link',
  //   icon: 'fa fa-cc-visa',
  // },
  {
    state: 'announcement',
    name: 'Announcement',
    type: 'link',
    icon: 'fa fa-bullhorn',
  },
  {
    state: 'images',
    name: 'Photo Gallery',
    type: 'link',
    icon: 'fa fa-image',
  },
  {
    state: 'timetable',
    name: 'Time-Table',
    type: 'link',
    icon: 'fa fa-calendar-check-o',
  },
  {
    state: 'studymaterial',
    name: 'Teacher Notes',
    type: 'link',
    icon: 'fa fa-book',
  },
  {
    state: 'feepayment',
    name: 'Fee Payment',
    type: 'link',
    icon: 'fa fa-credit-card'
  },
  // {
  //   state: 'holidaycalendar',
  //   name: 'Holiday Calendar',
  //   type: 'link',
  //   icon: 'fa fa-calendar-o'
  // },
  {
    state: 'markattendance',  
    name: 'Manage Attendance',
    type: 'sub',
    icon: 'fa fa-check-square-o',
    children: [
      { state: 'list-holidaycalendar', name: 'Holiday List' },
      { state: 'add-markattendance', name: 'Mark Attendance' },
      { state: 'view-editattendance', name: 'View & Edit Attendance'}
    ]
  },
  {
    state: 'reportcard',
    name: 'Report Card',
    type: 'link',
    icon: 'fa fa-list-alt'
  }
  
];

const principal = [

  {
    state: 'dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'icon-speedometer icons',
  },

  {
    state: 'master',
    name: 'Master Configuration',
    type: 'sub',
    icon: 'icon-grid icons',
    children: [
      {state: 'boards', name: 'Boards'},
      {state: 'shifts', name: 'Shifts'},
      {state: 'grades', name: 'Grades'},
      {state: 'divisions', name: 'Divisions'},
      {state: 'subjects', name: 'Subjects'},
      {state: 'logintype', name: 'Access Rights' }
    ]
  },
  {
    state: 'user',
    name: 'Staff Management',
    type: 'sub',
    icon: 'icon-user icons',
    children: [
      {state: 'manage-user', name: 'Manage Staff'},
      {state: 'manage-group', name: 'Manage Group'},
      {state: 'assign-user', name: 'Assign Staff'}
    ]
  },
  {
    state: 'student',
    name: 'Student-Parent Management',
    type: 'link',
    icon: 'fa fa-child',
  },
  {
    state: 'dailyactivity',
    name: 'Class Activity',
    type: 'link',
    icon: 'fa fa-tasks',
  },
  
  {
    state: 'appreciationcard',
    name: 'Appreciation card',
    type: 'link',
    icon: 'fa fa-id-card',
  },
  {
    state: 'acadmeiccalendar',
    name: 'Academic calendar',
    type: 'link',
    icon: 'fa fa-calendar',
  },
  // {
  //   state: 'feepayment',
  //   name: 'Fee Payment',
  //   type: 'link',
  //   icon: 'fa fa-cc-visa',
  // },
  {
    state: 'announcement',
    name: 'Announcement',
    type: 'link',
    icon: 'fa fa-bullhorn',
  },

   {
    state: 'images',
    name: 'Photo Gallery',
    type: 'link',
    icon: 'fa fa-image',
  },
  {
    state: 'timetable',
    name: 'Time-Table',
    type: 'link',
    icon: 'fa fa-calendar-check-o',
  },
  {
    state: 'studymaterial',
    name: 'Teacher Notes',
    type: 'link',
    icon: 'fa fa-book',
  },
  {
    state: 'feepayment',
    name: 'Fee Payment',
    type: 'link',
    icon: 'fa fa-credit-card'
  },
  // {
  //   state: 'holidaycalendar',
  //   name: 'Holiday Calendar',
  //   type: 'link',
  //   icon: 'fa fa-calendar-o'
  // },
  {
    state: 'markattendance',  
    name: 'Manage Attendance',
    type: 'sub',
    icon: 'fa fa-check-square-o',
    children: [
      { state: 'list-holidaycalendar', name: 'Holiday List' },
      { state: 'add-markattendance', name: 'Mark Attendance' },
      { state: 'view-editattendance', name: 'View & Edit Attendance'}
    ]
  },
  {
    state: 'reportcard',
    name: 'Report Card',
    type: 'link',
    icon: 'fa fa-list-alt'
  }

];


const teacher = [
  {
    state: 'dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'icon-speedometer icons',
  },
 
  {
    state: 'dailyactivity',
    name: 'Class Activity',
    type: 'link',
    icon: 'fa fa-tasks',
  },
  
  {
    state: 'appreciationcard',
    name: 'Appreciation card',
    type: 'link',
    icon: 'fa fa-id-card',
  },

  {
    state: 'images',
    name: 'Photo Gallery',
    type: 'link',
    icon: 'fa fa-image',
  },
  {
    state: 'timetable',
    name: 'Time-Table',
    type: 'link',
    icon: 'fa fa-calendar-check-o',
  },
  {
    state: 'studymaterial',
    name: 'Teacher Notes',
    type: 'link',
    icon: 'fa fa-book'
  },  
  {
    state: 'chat',
    name: 'Chat',
    type: 'link',
    icon: 'fa fa-comments-o',
  },
  {
    state: 'markattendance',  
    name: 'Manage Attendance',
    type: 'sub',
    icon: 'fa fa-check-square-o',
    children: [
      { state: 'list-holidaycalendar', name: 'Holiday List' },
      { state: 'add-markattendance', name: 'Mark Attendance' },
      { state: 'view-editattendance', name: 'View & Edit Attendance'}
    ]
  },
  {
    state: 'reportcard',
    name: 'Report Card',
    type: 'link',
    icon: 'fa fa-list-alt'
  }
];


const accountant = [
  {
    state: 'dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'icon-speedometer icons',
  },
  {
    state: 'feepayment',
    name: 'Fee Payment',
    type: 'link',
    icon: 'fa fa-credit-card'
  },
];


@Injectable()
export class MenuItems {

  getAll(): Menu[] {
    return systemadmin;
  }
  add(menu: any) {
    systemadmin.push(menu);
  }

  getAll2(): Menu[] {
    return instituteadmin;
  }
  add2(menu: any) {
    instituteadmin.push(menu);
  }

  getAll3(): Menu[] {
    return schooladmin;
  }
  add3(menu: any) {
    schooladmin.push(menu);
  }
  

  getAll4(): Menu[] {
    return principal;
  }
  add4(menu: any) {
    principal.push(menu);
  }

  getAll5(): Menu[] {
    return teacher;
  }
  add5(menu: any) {
    teacher.push(menu);
  }

  getAll6(): Menu[] {
    return accountant;
  }
  add6(menu: any) {
    accountant.push(menu);
  }

}
