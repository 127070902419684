import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  /* Ashwini ip */
  // url = 'http://127.0.0.1:3030/api/';
  // imageurl = 'http://127.0.0.1:3030/';
  // chaturl = 'http://127.0.0.1:3030';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';


  // Anjali
  // url = 'http://192.168.1.227/api/';
  // imageurl = 'http://192.168.1.227/';
  // chaturl = 'http://192.168.1.227';
  // chatkey= 'a18e74607b08b73fde38';

  // Anjali local

  // url = 'http://192.168.0.104/api/'; 
  // imageurl = 'http://192.168.0.104/';
  // chaturl = 'http://192.168.0.104';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';  


  // url = 'http://192.168.0.110/api/';
  // imageurl = 'http://192.168.0.110/';
  // chaturl = 'http://192.168.0.110';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';

  // url = 'http://192.168.0.6/api/';
  // imageurl = 'http://192.168.0.6/';
  // chaturl = 'http://192.168.0.6';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';

  // url = 'http://192.168.0.5/api/';
  // imageurl = 'http://192.168.0.5/';
  // chaturl = 'http://192.168.0.5';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';

  // url = 'http://192.168.0.5/api/';
  // imageurl = 'http://192.168.0.5/';
  // chaturl = 'http://192.168.0.5';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';


  // https://dev.navitusschool.com/


// debo server new dev.navitus

  // url = 'http://127.0.0.1:8000/api/';
  // imageurl = 'http://127.0.0.1:8000/';
  // chaturl = 'http://127.0.0.1:8000';
  // chatkey= 'c3d6a5c9891e316f9456';
  // chatname = 'school';

  // devcms2

  //  url = 'https://cmsdev2.navitusschool.com/api/';
  //  imageurl = 'https://cmsdev2.navitusschool.com/';
  // chaturl = 'https://cmsdev2.navitusschool.com';
  //  chatkey= 'c3d6a5c9891e31ng 6f9456';
  //  chatname = 'school';

  // url = 'https://cmslive.navitusschool.com/api/';
  // imageurl = 'https://cmslive.navitusschool.com/';
  // chaturl = 'https://cmslive.navitusschool.com';
  // chatkey= 'c3d6a5c9891e316f9456';
  // chatname = 'school';

//   url = 'https://cmsbeta2.0.navitusschool.com/api/';
//   imageurl = 'https://cmsbeta2.0.navitusschool.com/';
//  chaturl = 'https://cmsbeta2.0.navitusschool.com';
//   chatkey= 'c3d6a5c9891e316f9456';
//   chatname = 'school';
 
 // For LOCAL Instance
//  url = 'http://127.0.0.1:8000/api/';
//  imageurl = 'http://127.0.0.1:8000/';
//  chaturl = 'http://127.0.0.1:8000';
//  chatkey= 'c3d6a5c9891e316f9456';
//  chatname = 'school';

  // For Alpha Instance
  // url = 'https://cmsalpha.navitusschool.com/api/';
  // imageurl = 'https://cmsalpha.navitusschool.com/';
  // chaturl = 'https://cmsalpha.navitusschool.com';
  // chatkey= 'c3d6a5c9891e316f9456';
  // chatname = 'school';

  //debo beta instance 

  // url = 'https://cmsbeta.navitusschool.com/api/';
  // imageurl = 'https://cmsbeta.navitusschool.com/';
  // chaturl = 'https://cmsbeta.navitusschool.com';
  // chatkey= 'c3d6a5c9891e316f9456';
  // chatname = 'school';

//live url build

url = 'https://cmslive.navitusschool.com/api/';
imageurl = 'https://cmslive.navitusschool.com/';
chaturl = 'https://cmslive.navitusschool.com';
chatkey= 'c3d6a5c9891e316f9456';
chatname = 'school';

  //debo
  // url = 'http://192.168.0.3/api/';
  // imageurl = 'http://192.168.0.3/';
  // chaturl = 'http://192.168.0.3';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';  


  //  url = 'http://192.168.0.104/api/';
  // imageurl = 'http://192.168.0.104/';
  // chaturl = 'http://192.168.0.104';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';


  //  url = 'http://192.168.43.181/api/';
  // imageurl = 'http://192.168.43.181/';
  // chaturl = 'http://192.168.43.181';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';



  // 192.168.43.181

  // url = 'http://192.168.0.105/api/';
  // imageurl = 'http://192.168.0.105/';
  // chaturl = 'http://192.168.0.105';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';  

  // url = 'http://192.168.43.181/api/';
  // imageurl = 'http://192.168.43.181/';
  // chaturl = 'http://192.168.43.181';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';  



  // url = 'http://192.168.0.101/api/';
  // imageurl = 'http://192.168.0.101/';
  // chaturl = 'http://192.168.0.101';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';

  //debo
  // url = 'http://192.168.0.106/api/';
  // imageurl = 'http://192.168.0.106/';
  // chaturl = 'http://192.168.0.106';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';  

  // url = 'http://192.168.0.106/api/';
  // imageurl = 'http://192.168.0.106/';
  // chaturl = 'http://192.168.0.106';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';  


  /* Irshad ip */
  // url = 'http://192.168.1.229/api/';
  //imageurl = 'http://192.168.1.229/';
  // chaturl = 'http://192.168.1.237';
  // chatkey= 'a18e74607b08b73fde38';
  // chatname = 'school';

  
  /* Usman ip */
  // url = 'http://192.168.1.236/api/';
  // imageurl = 'http://192.168.1.236/';
  
  // chaturl = 'http://192.168.1.237';
  // chatkey= 'a18e74607b08b73fde38';
    // chatname = 'school';


  /* Server Ip */ 
  // url = 'http://192.168.1.152/api/';
  // imageurl = 'http://192.168.1.152/';
  // chaturl = 'http://192.168.1.152';
  // chatkey= 'c3d6a5c9891e316f9456';
  // chatname = 'school';

 

   /* Internet Ip */ 
  //   url = 'https://beta.navitusschool.com/api/';
  //  imageurl = 'https://beta.navitusschool.com/';
  //   chaturl = 'https://beta.navitusschool.com';
  //  chatkey= '7b42c29905060c4fd6c5';
  //   chatname = 'schoolamazonserver';

  // url = 'https://live.navitusschool.com/api/';
  // imageurl = 'https://live.navitusschool.com/';
  // chaturl = 'https://live.navitusschool.com';
  // chatkey= '1e02552347b26ab3f5f8';
  //  chatname = 'schoolserverproduction';

  //   url = 'https://cmsdev.navitusschool.com/api/';
  //  imageurl = 'https://cmsdev.navitusschool.com/';
  //  chaturl = 'https://cmsdev.navitusschool.com';
  //  chatkey= '7b42c29905060c4fd6c5';
  //   chatname = 'schoolamazonserver';
  
  
  reqheadermain = new HttpHeaders({'Authorization':'Bearer '+this.token.getToken()});

  constructor(
    public http: HttpClient,
    public token:TokenService
  ) { }
}


